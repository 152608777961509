import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Routes, Route, Navigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Import Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// Impor datepicker
import "dayjs/locale/tr"
import { DatesProvider } from "@mantine/dates"
import { useEffect } from "react"
import axios from "lib/axios"

// Activating fake backend
//fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))

  const Layout = getLayout(layoutType)

  const [user, setUser] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [loggedIn, setLoggedIn] = React.useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      if (!localStorage.getItem("authUser")) {
        setLoading(false)
        return
      }
      try {
        if (
          localStorage.getItem("userToken") &&
          localStorage.getItem("authUser")
        ) {
          const { data } = await axios.post("/auth/checkToken",{}, {
            headers: {
              Authorization: `${localStorage.getItem("userToken")}`,
              user: `${localStorage.getItem("authUser")}`,
            },
          })
          if (data.success) {
            axios.defaults.headers.common["Authorization"] =
              localStorage.getItem("userToken")
            axios.defaults.headers.common["user"] =
              localStorage.getItem("authUser")

            setUser(JSON.parse(localStorage.getItem("authUser")))
            setLoggedIn(true)
            setLoading(false)
          } else {
            localStorage.removeItem("userToken")
            localStorage.removeItem("authUser")
            setLoggedIn(false)
          }
        } else {
          localStorage.removeItem("userToken")
          localStorage.removeItem("authUser")
          setLoggedIn(false)
        }
      } catch (error) {
        localStorage.removeItem("userToken")
        localStorage.removeItem("authUser")
        setLoggedIn(false)
      } finally {
        setLoading(false)
      }
    }

    checkAuth()
  }, [])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const token = localStorage.getItem("userToken")

    if (user) {
      axios.defaults.headers.common["Authorization"] = token
      axios.defaults.headers.common["user"] = user
    }
  }, [localStorage.getItem("authUser"), localStorage.getItem("userToken")])

  if (loading) {
    return <div>Yükleniyor...</div>
  }

  // if (!loggedIn && !loading) {
  //   return <Navigate to="/login" replace={true} />
  // }

  return (
    <React.Fragment>
      <ToastContainer position="bottom-right" />
      <DatesProvider settings={{ locale: "tr" }}>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => {
            if (route.require) {
              const permitted = route.require.map(item => {
                if (user && user.role) {
                  return user.role === item.role
                } else {
                  return false
                }
              })

              if (permitted[0] || permitted[1]) {
                return (
                  <Route
                    path={route.path}
                    element={
                      <Authmiddleware>
                        <Layout>{route.component}</Layout>
                      </Authmiddleware>
                    }
                    key={idx}
                    exact={true}
                  />
                )
              } else {
                return (
                  <Route
                    path={route.path}
                    element={<Navigate to="/pages-404" replace={true} />}
                    key={idx}
                    exact={true}
                  />
                )
              }
            } else {
              return (
                <Route
                  path={route.path}
                  element={
                    <Authmiddleware>
                      <Layout>{route.component}</Layout>
                    </Authmiddleware>
                  }
                  key={idx}
                  exact={true}
                />
              )
            }
          })}
          <Route
            path="*"
            element={<Navigate to="/pages-404" replace={true} />}
          />
        </Routes>
      </DatesProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
