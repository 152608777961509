import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate } from "react-router-dom"

import classnames from "classnames"

import axios from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const QRAdd = () => {
  const Navigate = useNavigate()

  const [active, setActive] = React.useState(true)

  const [form, setForm] = React.useState()

  const [name, setName] = React.useState("")

  const [qr, setQr] = React.useState([])

  const [title, setTitle] = React.useState("")
  const [value, setValue] = React.useState("")
  const [file, setFile] = React.useState("")
  const [inputType, setInputType] = React.useState("link")  // State to manage the type of input
  //useref file
  const fileInputRef = React.useRef()
  const fileInputRef2 = React.useRef()

  const [special, setSpecial] = React.useState(false)
  const [specialUrl, setSpecialUrl] = React.useState("")
 
  document.title = "Safkar Panel - QR Ekle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const handleInputChange = (e) => {
    const { value, files } = e.target;
    setInputType(e.target.value || "link");
    if (files) {
      setValue(files[0]);  // For file inputs, store the file object
    } else {
      setValue(value);     // For text inputs, store the string
    }
  };

  const renderInput = () => {
    switch (inputType) {
      case "link":
        return (
          <div className="mb-3">
          <label htmlFor="categoryname" className="form-label">
            Link
          </label>
          <input
            id="categoryname"
            name="categoryname"
            type="text"
            className="form-control"
            placeholder="Link"
            onChange={e => {
              setValue(e.target.value)
            }}
          />
        </div>
        );
      case "image":
        return(
        <div className="mb-3">
        <label htmlFor="categoryname" className="form-label">
          Resim
        </label>
        <input
          id="categoryname"
          name="categoryname"
          type="file"
          accept=".png, .jpeg, .jpg"
          ref={fileInputRef}
          className="form-control"
          onChange={e => {
            setFile(e.target.files[0])
          }}
        />
        <div className="mt-2">
        {
          inputType == "image" && file && <img src={
            URL.createObjectURL(file)
          } alt="image" className="img-fluid" />
        }

        </div>
      </div>
        );
      case "file":
        return (
          <div className="mb-3">
            <label htmlFor="categoryname" className="form-label">
              Dosya
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="file"
              accept=".pdf, .docx, .doc, .xls, .xlsx"
              ref={fileInputRef2}
              className="form-control"
              onChange={e => {
                setFile(e.target.files[0])
          }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const isFaqForm = obj => {
    return true
  }

  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  const startsWithNumber = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    return /^\d/.test(text) ? true : 0;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Ekip" breadcrumbItem="QR Ekle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={async e => {
                      e.preventDefault()
                      if(special && !specialUrl){
                        toast.error("Özel Link Giriniz")
                        return
                      }
                      if(special && startsWithNumber(specialUrl)){
                        toast.error("Özel Link Sayı ile başlayamaz")
                        return
                      }
                      if (inputType === "file"){
                        if (file) {
                            const fileFormData = new FormData()
                            fileFormData.append("files", file)
                            const fileResponse = await axios.post("/qr/pdf", fileFormData)

                            if (fileResponse.status === 200) {
                              axios
                                    .post("/qr", {
                                      title: title,
                                      value: fileResponse.data.files[0].filename,
                                      level: "L",
                                      size: 256,
                                      type: "file",
                                      active: active,
                                      special: special,
                                      specialUrl: specialUrl
                                    })
                                    .then(response => {
                                      if (response.status == 200) {
                                        toast.success("QR Eklendi")
                                        Navigate("/qr")
                                      }else if(response.status == 400){
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                                    })
                                    .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                            }
                          }else{
                            toast.error("Dosya Seçiniz")
                          }
                      }else if (inputType === "image"){
                        if (file) {
                              const formData = new FormData()
                              formData.append("images", file)
                              await axios
                                .post("/qr/image", formData)
                                .then(response => {
                                  if (response.data) {
                                    axios
                                    .post("/qr", {
                                      title: title,
                                      value: response.data.qrimage[0].image,
                                      level: "L",
                                      size: 256,
                                      type: "image",
                                      active: active,
                                      special: special,
                                      specialUrl: specialUrl
                                    })
                                    .then(response => {
                                      if (response.status == 200) {
                                        toast.success("QR Eklendi")
                                        Navigate("/qr")
                                      }else if(response.status == 400){
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                                    })
                                    .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                                  } else {
                                    toast.error("Resim Yükleme Başarısız")
                                  }
                                })
                                .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                        }else{
                          toast.error("Resim Seçiniz")
                        }
                      }else {
                        if (value) {
                        axios
                          .post("/qr", {
                            title: title,
                            value: value,
                            level: "L",
                            size: 256,
                            type: "link",
                            active: active,
                            special: special,
                            url: specialUrl
                          })
                          .then(response => {
                            if (response.status == 200) {
                              toast.success("QR Eklendi")
                              Navigate("/qr")
                            }else if(response.status == 400){
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                          })
                          .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                      }else{
                        toast.error("Link Giriniz")
                      }
                    }


                    }}
                  >
                    <h4 className="card-title">QR Ekle</h4>
                    <p className="card-title-desc">QR Ekle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/qr")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Ad
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            placeholder="QR Adı"
                            onChange={e => {
                              setTitle(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Tip</label>
                          <select className="form-control" onChange={(e) => {
                          setInputType(e.target.value)
                          setFile(null)
                          setValue("")
                          if (inputType === "image") {
                            fileInputRef.current.value = null
                          }
                          if (inputType === "file") {
                            fileInputRef2.current.value = null
                          }
                          }
                          }>
                            <option value="link">Link</option>
                            <option value="image">Resim</option>
                            <option value="file">Dosya</option>
                          </select>
                        </div>
                    </div>
                      <div className="col-lg-6">
                        {/*category name*/}
                        {
                          renderInput()
                        }
                      </div>
                      <div className="col-lg-6">
                          {/*product name*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Aktiflik
                            </label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              {active != null && (
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg"
                                  defaultChecked={active ? true : false}
                                  onChange={e => {
                                    setActive(e.target.checked)
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {/*product name*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Özel Link Aktif Edilsin Mi? (Opsiyonel)
                            </label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              {special != null && (
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg"
                                  defaultChecked={special ? true : false}
                                  onChange={e => {
                                    setSpecial(e.target.checked)
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          special && <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="categoryname" className="form-label">
                              Özel Link
                            </label>
                            <input
                              id="categoryname"
                              name="categoryname"
                              type="text"
                              className="form-control"
                              defaultValue={specialUrl}
                              placeholder="Özel Link"
                              onChange={e => {
                                setSpecialUrl(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                        }
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Ekle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/qr")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QRAdd
