import React, { useEffect } from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useNavigate, useParams } from "react-router-dom"

import QRCode from "react-qr-code"

import classnames from "classnames"

import axios, { API_URL } from "lib/axios"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Editor } from "ckeditor5-custom-build/build/ckeditor"

const QRAdd = () => {
  const Navigate = useNavigate()

  const params = useParams()



  const [form, setForm] = React.useState()

  const [name, setName] = React.useState("")

  const [qr, setQr] = React.useState([])


  const [title, setTitle] = React.useState("")
  const [value, setValue] = React.useState("")
  const [file, setFile] = React.useState("")
  const [inputType, setInputType] = React.useState("link")
  const [defaultInputType, setDefaultInputType] = React.useState("link")
  const [linkvalue, setLinkValue] = React.useState("")
  const [active, setActive] = React.useState()
  const [special, setSpecial] = React.useState()
  const [specialUrl, setSpecialUrl] = React.useState("")


  //useref file
  const fileInputRef = React.useRef()
  const fileInputRef2 = React.useRef()
  

  document.title = "Safkar Panel - QR Düzenle"

  const [customActiveTab, setcustomActiveTab] = React.useState("0")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  useEffect(() => {
    axios
      .get("/qr?id=" + params.id)
      .then(response => {
        console.log(response.data)
        setQr(response.data)
        setTitle(response.data.title)
        setValue(response.data.value)
        setInputType(response.data.type)
        setDefaultInputType(response.data.type)
        setActive(response.data.active)
        setSpecial(response.data.special)
        setSpecialUrl(response.data.url)
        if(response.data.type === "link"){
          setLinkValue(response.data.value)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [params.id])

  const isFaqForm = obj => {
    return true
  }
  
  const startsWithNumber = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    return /^\d/.test(text) ? true : 0;
  };


  const setFormValueByIndex = (index, key, value) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      newForm[index][key] = value.toString()
    }
    setForm(newForm)
  }

  const getFormValueByIndex = (index, key) => {
    const newForm = [...form]
    if (isFaqForm(newForm[index])) {
      if (newForm[index]) {
        return newForm[index][key]
      } else {
        return ""
      }
    }
    return ""
  }

  const turkishToEnglish = (text) => {
    const turkishMap = {
      'ç': 'c', 'ğ': 'g', 'ı': 'i', 'ö': 'o', 'ş': 's', 'ü': 'u',
      'Ç': 'C', 'Ğ': 'G', 'İ': 'I', 'Ö': 'O', 'Ş': 'S', 'Ü': 'U'
    };

    return text.split('').map(char => turkishMap[char] || char).join('');
  };

  const formatURL = (text) => {
    const englishText = turkishToEnglish(text);
    var result = englishText
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^a-z0-9-]/g, '');
    return result
  };

  const renderInput = () => {
    switch (inputType) {
      case "link":
        return (
          <div className="mb-3">
          <label htmlFor="categoryname" className="form-label">
            Link
          </label>
          <input
            id="categoryname"
            name="categoryname"
            type="text"
            className="form-control"
            defaultValue={linkvalue}
            placeholder="Link"
            onChange={e => {
              setLinkValue(e.target.value)
            }}
          />
        </div>
        );
      case "image":
        return(
        <div className="mb-3">
        <label htmlFor="categoryname" className="form-label">
          Resim
        </label>
        <input
          id="categoryname"
          name="categoryname"
          type="file"
          accept=".png, .jpeg, .jpg"
          ref={fileInputRef}
          className="form-control"
          onChange={e => {
            setFile(e.target.files[0])
          }}
        />
        <div className="mt-2">
        {
          inputType == "image" && defaultInputType == "image" &&
          <div>
          <h6 className="text-muted text-primary" style={{fontWeight:'bold', marginTop:'12px'}}>Resim:</h6>
          <img 
          style={{width:'200px', height:'auto'}}
          src={
            API_URL + "/api/image?image=" + value + "&path=qr"
          } alt="image" className="img-fluid" />
          </div>
        }

        </div>
      </div>
        );
      case "file":
        return (
          <div className="mb-3">
            <label htmlFor="categoryname" className="form-label">
              Dosya
            </label>
            <input
              id="categoryname"
              name="categoryname"
              type="file"
              accept=".pdf, .docx, .doc, .xls, .xlsx"
              ref={fileInputRef2}
              className="form-control"
              onChange={e => {
                setFile(e.target.files[0])
          }}
            />
          <div className="mt-2">
          {
            inputType == "file" && file && <div>
            <h6 className="text-muted text-primary" style={{fontWeight:'bold', marginTop:'12px'}}>Yüklenecek Dosya Adı:</h6>
            <h6 className="text-muted">{file.name}</h6>
            </div>
          }
          {
            inputType == "file" && defaultInputType == "file" && <div>
            <h6 className="text-muted text-primary" style={{fontWeight:'bold', marginTop:'12px'}}>Yüklü Dosya Adı:</h6>
            <h6 className="text-muted">{value}</h6>
            </div>
          }
          </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="QR" breadcrumbItem="QR Düzenle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={async e => {
                      e.preventDefault()
                      if(special && !specialUrl){
                        toast.error("Özel Link Giriniz")
                        return
                      }
                      if(special && startsWithNumber(specialUrl)){
                        toast.error("Özel Link Sayı ile başlayamaz")
                        return
                      }
                      if (inputType === "file"){
                        if (file) {
                            const fileFormData = new FormData()
                            fileFormData.append("files", file)
                            const fileResponse = await axios.post("/qr/pdf", fileFormData)


                            if (fileResponse.status === 200) {
                              axios
                                    .put("/qr", {
                                      id: params.id,
                                      title: title,
                                      value: fileResponse.data.files[0].filename,
                                      level: "L",
                                      size: 256,
                                      type: "file",
                                      active: active,
                                      special: special,
                                      url: specialUrl
                                    })
                                    .then(response => {
                                      if (response.status == 200) {
                                        toast.success("QR Düzenlendi")
                                        Navigate("/qr")
                                      }else if(response.status == 400){
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                                    })
                                    .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                            }
                          }else{
                            if(defaultInputType === "file"){
                            axios
                          .put("/qr", {
                            id: params.id,
                            title: title,
                            level: "L",
                            size: 256,
                            type: defaultInputType,
                            active: active,
                            special: special,
                            url: specialUrl
                          })
                          .then(response => {
                            if (response.status == 200) {
                              toast.success("QR Düzenlendi")
                              Navigate("/qr")
                            }else if(response.status == 400){
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                          })
                          .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                            }else{
                              toast.error("Dosya Seçiniz")
                            }
                      }
                      }else if (inputType === "image"){
                        if (file) {
                              const formData = new FormData()
                              formData.append("images", file)
                              await axios
                                .post("/qr/image", formData)
                                .then(response => {
                                  if (response.data) {
                                    axios
                                    .put("/qr", {
                                      id: params.id,
                                      title: title,
                                      value: response.data.qrimage[0].image,
                                      level: "L",
                                      size: 256,
                                      type: "image",
                                      active: active,
                                      special: special,
                                      url: specialUrl
                                    })
                                    .then(response => {
                                      if (response.status == 200) {
                                        toast.success("QR Düzenlendi")
                                        Navigate("/qr")
                                      }else if(response.status == 400){
                                        console.log(response.data)
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                                    })
                                    .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                                  } else {
                                    toast.error("Resim Yükleme Başarısız")
                                  }
                                })
                                .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                        }
                                else{
                          if(defaultInputType === "image"){
                            axios
                          .put("/qr", {
                            id: params.id,
                            title: title,
                            level: "L",
                            size: 256,
                            type: defaultInputType,
                            active: active,
                            special: special,
                            url: specialUrl
                          })
                          .then(response => {
                            if (response.status == 200) {
                              toast.success("QR Düzenlendi")
                              Navigate("/qr")
                            }else if(response.status == 400){
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                          })
                          .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                      }
                      else{
                        toast.error("Resim Seçiniz")
                      }
                      }
                        }else {
                          if (!linkvalue)
                          {
                            toast.error("Link Giriniz")
                            return
                          }
                        axios
                          .put("/qr", {
                            id: params.id,
                            title: title,
                            value: linkvalue,
                            level: "L",
                            size: 256,
                            type: "link",
                            active: active,
                            special: special,
                            url: specialUrl
                          })
                          .then(response => {
                            if (response.status == 200) {
                              toast.success("QR Düzenlendi")
                              Navigate("/qr")
                            }else if(response.status == 400){
                                        if(response.data.showmsg == true)
                                        {
                                          toast.error(response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                                      }
                          })
                          .catch(error => {
                            if(error.response.data.showmsg == true)
                                        {
                                          toast.error(error.response.data.error)
                                        }else{
                                          toast.error("QR Düzenlenemedi")
                                        }
                          })
                      }

                    }}
                  >
                    <h4 className="card-title">QR Düzenle</h4>
                    <p className="card-title-desc">QR Düzenle</p>
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        Navigate("/qr")
                      }}
                    >
                      Geri
                    </button>
                    <div className="row">
                      <div className="col-lg-6">
                        {/*category name*/}
                        <div className="mb-3">
                          <label htmlFor="categoryname" className="form-label">
                            Ad
                          </label>
                          <input
                            id="categoryname"
                            name="categoryname"
                            type="text"
                            className="form-control"
                            defaultValue={title}
                            placeholder="QR Adı"
                            onChange={e => {
                              setTitle(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Tip</label>
                          <select className="form-control" value={inputType}
                          onChange={(e) => {
                          setInputType(e.target.value)
                          setFile(null)
                          if (inputType === "image") {
                            fileInputRef.current.value = null
                          }
                          if (inputType === "file") {
                            fileInputRef2.current.value = null
                          }
                          }
                          }>
                            <option value="link">Link</option>
                            <option value="image">Resim</option>
                            <option value="file">Dosya</option>
                          </select>
                        </div>
                    </div>
                      <div className="col-lg-6">
                        {/*category name*/}
                        {
                          renderInput()
                        }
                      </div>
                      <div className="col-lg-6">
                          {/*product name*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Aktiflik
                            </label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              {active != null && (
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg"
                                  defaultChecked={active}
                                  onChange={e => setActive(e.target.checked)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {/*product name*/}
                          <div className="mb-3">
                            <label htmlFor="productname" className="form-label">
                              Özel Link Aktif Edilsin Mi? (Opsiyonel)
                            </label>
                            <div className="form-check form-switch form-switch-lg mb-3">
                              {special != null && (
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg"
                                  defaultChecked={special}
                                  onChange={e => setSpecial(e.target.checked)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          special && <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="categoryname" className="form-label">
                              Özel Link
                            </label>
                            <input
                              id="categoryname"
                              name="categoryname"
                              type="text"
                              className="form-control"
                              defaultValue={specialUrl}
                              value={specialUrl}
                              placeholder="Özel Link"
                              onChange={e => {
                                var formattedUrl = formatURL(e.target.value)
                                setSpecialUrl(formattedUrl)
                              }}
                            />
                          </div>
                        </div>
                        }
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mt-4 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary w-md  mb-2"
                          >
                            Düzenle
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger w-md mx-3 mb-2"
                            onClick={() => {
                              Navigate("/qr")
                            }}
                          >
                            Geri
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QRAdd
