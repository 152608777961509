import axios from "axios"

export const API_URL = "https://webservis.safkar.com"
export const FRONTEND_URL = "https://safkar.com"
//export const API_URL = "http://localhost:3000"
//export const FRONTEND_URL = "http://localhost:3001"

var instance

instance = axios.create({
  baseURL: API_URL + "/api",
})

export default instance

