import axios from "lib/axios"
import React, { useState, useEffect } from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      if (!localStorage.getItem("authUser")) {
        setLoading(false)
        return
      }
      try {
        if (
          localStorage.getItem("userToken") &&
          localStorage.getItem("authUser")
        ) {
          const { data } = await axios.get("/auth/checkToken", {
            headers: {
              Authorization: `${localStorage.getItem("userToken")}`,
              user: `${localStorage.getItem("authUser")}`,
            },
          })
          if (data.success) {
            axios.defaults.headers.common["Authorization"] =
              localStorage.getItem("userToken")
            axios.defaults.headers.common["user"] =
              localStorage.getItem("authUser")
            setLoggedIn(true)
            setLoading(false)
          } else {
            localStorage.removeItem("userToken")
            localStorage.removeItem("authUser")
            setLoggedIn(false)
          }
        } else {
          localStorage.removeItem("userToken")
          localStorage.removeItem("authUser")
          setLoggedIn(false)
        }
      } catch (error) {
        localStorage.removeItem("userToken")
        localStorage.removeItem("authUser")
        setLoggedIn(false)
      } finally {
        setLoading(false)
      }
    }

    checkAuth()
  }, [])

  if (loading) {
    return <div>Yükleniyor...</div>
  }

  if (!loggedIn) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
