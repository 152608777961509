import React, { useState, useEffect } from "react"
import { Input, Select } from "@mantine/core"
import ReactDataTable from "react-data-table-component"
import { Spinner } from "reactstrap"

const PAGE_SIZES = [5, 10, 15, 20, 50]

var defaultData = [
  { id: 1, name: "" },
  { id: 2, name: "" },
]

var defaultColumns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
    right: true,
  },
  { name: "Name", selector: "name", sortable: true },
]

function DataTableMantine({
  data = defaultData,
  columns = defaultColumns,
  Expansion,
  filterField,
}) {
  const [loading, setLoading] = useState(true)
  const [records, setRecords] = useState(data)
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "id",
    direction: "asc",
  })
  const [filterValue, setFilterValue] = useState("")

  const handleFilterValueChange = event => {
    const value = event.currentTarget.value
    setFilterValue(value)
  }

  const handleSort = (column, sortDirection) => {
    setSortStatus({ columnAccessor: column.selector, direction: sortDirection })
  }

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false)
    } else {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }, [data])

  const getValueByPath = (obj, path) => {
    return path.split(/[[\].]+/).reduce((o, k) => (k && o ? o[k] : o), obj)
  }

  useEffect(() => {
    if (filterValue) {
      const filteredData = data.filter(item => {
        const valueAtPath = getValueByPath(item, filterField)
        const doesInclude = valueAtPath
          ? valueAtPath.toLowerCase().includes(filterValue.toLowerCase())
          : false
        return doesInclude
      })
      console.log("Filtering with value:", filterValue) // Log the filter value
      console.log("Filtering on field:", filterField) // Log the filter field
      console.log("Filtered data:", filteredData) // Log the filtered data
      setRecords(filteredData)
    } else {
      setRecords(data)
    }
  }, [filterValue, data])

  return (
    <div>
      <Input
        value={filterValue}
        onChange={handleFilterValueChange}
        placeholder="Arayınız..."
        sx={{
          marginBottom: "10px",
        }}
      />
      <ReactDataTable
        columns={columns}
        data={records}
        progressPending={loading}
        pagination
        progressComponent={<Spinner />}
        paginationTotalRows={data.length}
        paginationRowsPerPageOptions={PAGE_SIZES}
        onSort={handleSort}
        highlightOnHover
        expandableRows={Expansion ? true : false}
        {...(Expansion ? { expandableRowsComponent: Expansion } : {})}
        noDataComponent="Kayıt Bulunamadı"
      />
    </div>
  )
}

export default DataTableMantine
