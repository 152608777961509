import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/default.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import axios from "lib/axios"
import { toast } from "react-toastify"

const UserProfile = () => {
  //meta title
  document.title = "Profile | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const [idx, setidx] = useState(null)
  const [userFirstname, setUserFirstname] = useState("")
  const [userLastname, setUserLastname] = useState("")
  const [userMail, setUserMail] = useState("")
  const [username, setUsername] = useState("")
  const [oldPass, setOldPass] = useState("")
  const [newPass, setNewPass] = useState("")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      axios.get("/users/" + obj.id).then(res => {
        const { data } = res
        setidx(data.id)
        setUserFirstname(data.userFirstname)
        setUserLastname(data.userLastname)
        setUserMail(data.userMail)
        setUsername(data.username)
      })
    }
  }, [])

  if (!userFirstname) return null

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Panel" breadcrumbItem="Profil" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userFirstname + " " + userLastname}</h5>
                        <p className="mb-1">{userMail}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Bilgileri Degistir</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  axios
                    .post("/auth/changePass", {
                      id: idx,
                      username: username,
                      oldPass: oldPass,
                      newPass: newPass,
                    })
                    .then(res => {
                      if (res.status === 200) {
                        toast.success("Şifreniz başarıyla değiştirildi")
                        setOldPass("")
                        setNewPass("")
                        setUsername(res.data.username)
                      } else {
                        toast.error(res.data.message)
                      }
                    })
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Kullanıcı Adı</Label>
                  <Input
                    name="username"
                    value={username}
                    className="form-control"
                    placeholder="Kullanıcı Adı"
                    type="text"
                    onChange={e => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group mt-2">
                  <Label className="form-label">Eski Şifreniz</Label>
                  <Input
                    name="username"
                    value={oldPass}
                    className="form-control"
                    placeholder="Eski Şifreniz"
                    type="text"
                    onChange={e => setOldPass(e.target.value)}
                  />
                </div>
                <div className="form-group mt-2">
                  <Label className="form-label">Yeni Şifreniz</Label>
                  <Input
                    name="username"
                    value={newPass}
                    className="form-control"
                    placeholder="Yeni Şifreniz"
                    type="text"
                    onChange={e => setNewPass(e.target.value)}
                  />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Bilgileri Güncelle
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
