import React from "react"
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { useParams, useNavigate } from "react-router-dom"


import axios, { API_URL } from "lib/axios"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Components

import { toast } from "react-toastify"

const LanguageActive = () => {
  document.title = "Safkar Panel - Dil Aktiflik Ayarını Düzenle"
  const navigate = useNavigate()
  const [arr, setArr] = React.useState([])

  const [active, setActive] = React.useState(null)

  React.useEffect(() => {
    axios.get("/languageActive").then(res => {
      if (res.data && res.data != null) {
        setActive(res.data.active)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dil Aktiflik Ayarı" breadcrumbItem="Dil Aktiflik Ayarını Düzenle" />

          {/* Render Form */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      axios
                        .put("/languageActive", {
                          active: active,
                        })
                        .then(response => {
                          if (response.status == 200) {
                            toast.success("Güncelleme Başarılı")
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    }}
                  >
                    <h4 className="card-title">Dil Aktiflik Ayarını Düzenle</h4>
                    <p className="card-title-desc">Dil ayarlarını açmadan önce, lütfen tüm içeriklerin ingilizce bilgilerinin girili olduğuna emin olunuz. Girilmeyen içerikler boş veya eksik gözükebilir.</p>
                    {/* back button */}
                    <button
                      type="button"
                      className="btn btn-danger w-md mx-3 mb-2"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "20px",
                      }}
                      onClick={() => {
                        navigate("/main-page")
                      }}
                    >
                      Geri
                    </button>
                   { active != null && active != undefined ? <div className="col-lg-12">
                        {/*slider name*/}
                        <div className="mb-3">
                          <label htmlFor="slidername" className="form-label">
                            Aktiflik
                          </label>
                          <div className="form-check form-switch form-switch-lg mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizelg"
                                defaultChecked={active ? true : false}
                                onChange={e => {
                                  setActive(e.target.checked)
                                }}
                              />
                          </div>
                        </div>
                      </div> : <div className="col-lg-12">
                                <div className="mb-3">
                                <h4 className="card-title">Dil Ayarı Yükleniyor.</h4>
                                </div>
                                </div>}
                    {/*submit button right*/}
                    <div className="col-lg-12">
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary w-md  mb-2"
                        >
                          Güncelle
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger w-md mx-3 mb-2"
                          onClick={() => {
                            navigate("/main-page")
                          }}
                        >
                          Geri
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LanguageActive
